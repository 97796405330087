import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import * as Sentry from "@sentry/vue";
import { vMaska } from "maska";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import AnimateOnScroll from "primevue/animateonscroll";
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import Breadcrumb from "primevue/breadcrumb";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Chart from "primevue/chart";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import Image from "primevue/image";
import InlineMessage from "primevue/inlinemessage";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import Password from "primevue/password";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import RadioButton from "primevue/radiobutton";
import Sidebar from "primevue/sidebar";
import Skeleton from "primevue/skeleton";
import Slider from "primevue/slider";
import Steps from "primevue/steps";
import TabMenu from "primevue/tabmenu";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import TieredMenu from "primevue/tieredmenu";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import vue3GoogleLogin from "vue3-google-login";

import { isProduction } from "@/utils/envDetector";
import { getEnv, getLoginClientId, getSentryDSN } from "@/utils/envHelper";

import pt from "./pt.json";
import currency from "./utils/currency";
import datalayer from "./utils/datalayer";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const sentryConfig = {
  dsn: getSentryDSN(),
  integrations: [
    // Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  environment: getEnv(),
  enabled: isProduction(),

  // https://docs.sentry.io/platforms/javascript/guides/bun/configuration/options/#trace-propagation-targets
  tracePropagationTargets: ["localhost", "https://aqui.eletromidia.com.br"],
  tracesSampleRate: 0.2,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,

  trackComponents: true,
};

let sentryInitialized = false;
export default (app) => {
  if (!sentryInitialized) {
    sentryInitialized = true;
    sentryConfig.app = app;
    Sentry.init(sentryConfig);
  }
  app.use(PrimeVue, {
    locale: pt,
    pt: {
      calendar: {
        input: {
          class: "!py-3",
        },
      },
    },
  });

  app.use(pinia);

  app.use(currency);
  app.use(datalayer);
  app.use(ToastService);
  app.use(ConfirmationService);
  app.use(autoAnimatePlugin);
  app.use(vue3GoogleLogin, {
    clientId: getLoginClientId(),
  });

  app.directive("animateonscroll", AnimateOnScroll);
  app.directive("maska", vMaska);
  app.directive("tooltip", Tooltip);

  app.component("Accordion", Accordion);
  app.component("AccordionTab", AccordionTab);
  app.component("Avatar", Avatar);
  app.component("Badge", Badge);
  app.component("Breadcrumb", Breadcrumb);
  app.component("Button", Button);
  app.component("Card", Card);
  app.component("Calendar", Calendar);
  app.component("Carousel", Carousel);
  app.component("Chart", Chart);
  app.component("Checkbox", Checkbox);
  app.component("Chip", Chip);
  app.component("Column", Column);
  app.component("ColorPicker", ColorPicker);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("DataTable", DataTable);
  app.component("Dialog", Dialog);
  app.component("Divider", Divider);
  app.component("Dropdown", Dropdown);
  app.component("FileUpload", FileUpload);
  app.component("Image", Image);
  app.component("InlineMessage", InlineMessage);
  app.component("InputGroup", InputGroup);
  app.component("InputGroupAddon", InputGroupAddon);
  app.component("InputNumber", InputNumber);
  app.component("InputSwitch", InputSwitch);
  app.component("InputMask", InputMask);
  app.component("InputText", InputText);
  app.component("Menu", Menu);
  app.component("Menubar", Menubar);
  app.component("Message", Message);
  app.component("OverlayPanel", OverlayPanel);
  app.component("Paginator", Paginator);
  app.component("Password", Password);
  app.component("ProgressBar", ProgressBar);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("RadioButton", RadioButton);
  app.component("Sidebar", Sidebar);
  app.component("Skeleton", Skeleton);
  app.component("Slider", Slider);
  app.component("Steps", Steps);
  app.component("TabPanel", TabPanel);
  app.component("TabView", TabView);
  app.component("TabMenu", TabMenu);
  app.component("Tag", Tag);
  app.component("Textarea", Textarea);
  app.component("TieredMenu", TieredMenu);
  app.component("Toast", Toast);
};
